<template>
  <div>
    <table class="table table-sm">
      <thead class="thead-light">
        <tr>
          <th>#</th>
          <th>Plot</th>
          <th>Area</th>
          <th>LOI</th>
          <th>Sales Agreement</th>
          <th>Sales Deed</th>
          <th>Service Agreement</th>
          <th>Membership Agreement</th>
          <th width="10%">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(plot, index) in plots" :key="index">
          <td>{{ 1 + index }}</td>
          <td>{{ plot.code }}</td>
          <td>{{ plot.area }}</td>
          <td>
            <b-badge variant="success" v-if="plot.documentations.loi"
              >Completed</b-badge
            >
            <b-badge variant="danger" v-else>Pending</b-badge>
          </td>
          <td>
            <b-badge variant="success" v-if="plot.documentations.sale_agreement"
              >Completed</b-badge
            >
            <b-badge variant="danger" v-else>Pending</b-badge>
          </td>
          <td>
            <b-badge variant="success" v-if="plot.documentations.sale_deed"
              >Completed</b-badge
            >
            <b-badge variant="danger" v-else>Pending</b-badge>
          </td>
          <td>
            <b-badge
              variant="success"
              v-if="plot.documentations.service_agreement"
              >Completed</b-badge
            >
            <b-badge variant="danger" v-else>Pending</b-badge>
          </td>
          <td>
            <b-badge
              variant="success"
              v-if="plot.documentations.membership_agreement"
              >Completed</b-badge
            >
            <b-badge variant="danger" v-else>Pending</b-badge>
          </td>
          <td>
            <div class="btn-group mb-1" role="group">
              <router-link
                :to="'/plot/' + plot._id"
                class="btn btn-sm btn-primary"
              >
                <i class="fa fa-eye"></i>
              </router-link>
              <plot-edit
                :zones="zones"
                :index="index"
                :plot="plot"
                @plotEditEvent="updatedPlot"
              ></plot-edit>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import PlotEdit from '@/components/plots/PlotEdit';
export default {
  components: {
    PlotEdit,
  },
  props: ['plots', 'zones'],
  data() {
    return {
      selected: [],
      form: {
        name: '',
        area: '',
        boundaries: [],
        _id: '',
      },
      showEditModal: false,
    };
  },
  methods: {
    updatedPlot() {
      this.$emit('plotEditEvent');
    },
    submitForm() {
      let project_id = this.$route.params.id;
      window.axios.post('plot/' + project_id, this.zone_form).then((resp) => {
        console.log(resp);
      });
    },
    showEditModalMethod(index) {
      this.showEditModal = true;
      this.form.name = this.plots[index].name;
      this.form.code = this.plots[index].code;
      this.form.area = this.plots[index].area;
      this.form.boundaries = this.plots[index].boundaries;
      this.form._id = this.plots[index]._id;
    },
    selectedRow(index) {
      console.log(index);
      if (this.selected.includes(index)) {
        this.selected = [];
      } else {
        this.selected.push(index);
      }

      console.log(this.selected);
    },
  },
};
</script>
