<template>
  <div>
     
    <div class="mt-2" style="background-color:white;padding:10px">
    
      <zone-detail :zone="zoneDetails"></zone-detail>

      <div class="col-md-12 mt-2">
        <div class="row mb-2">
          <div class="col-md-6">
            <h5>Plots</h5>
          </div>
          <div class="col-md-6 text-right">
              <plot-create :zones="zoneDetails"  @plotCreateEvent="plotListReload"></plot-create>
          </div>
        </div>
        <template>
           <plot-list :zones="zoneDetails"  :plots = "plotDetails" @plotEditEvent="plotListReload"></plot-list>
 
        </template>
            </div>
    </div>
  </div>
</template>
<script>

import PlotCreate from "@/components/plots/PlotCreate";
import PlotList from "@/components/plots/PlotList";
import zoneDetail from "@/components/zones/details";

export default {
  components: {
    PlotCreate,
    PlotList,
    zoneDetail
  },
  data() {
    return {
      zoneDetails: false,
      plotDetails:false
    };
  },
  mounted() {
    this.getZoneDetails();
    this.getPlotDetails();
  },
  methods: {
    plotListReload(){
      this.getPlotDetails();
    },
    getZoneDetails() {
      let zone_id       = this.$route.params.zone_id;
      window.axios.get("zone/"+zone_id)
      .then((resp) => {   
        console.log("zone details")
        console.log(resp)    
        this.zoneDetails = resp.data.data;
     
      });
    },
    getPlotDetails() {
      let zone_id       = this.$route.params.zone_id;
      window.axios.get("zone-plots/"+zone_id)
      .then((resp) => {   
         console.log("zone plot")
        console.log(resp) 
        this.plotDetails = resp.data.data;
      });
    },
   
  },
};
</script>
