<template>
  <div>
    <b-button v-b-modal.modal-2 size="sm" variant="success">Add Plot</b-button>
    <b-modal id="modal-2" title="Create New Plot">
        <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
      <b-form @submit.prevent="submitPlotForm" enctype="multipart/form-data">
        <div class="row">
      
         
          <div class="col-md-12">
            <b-form-group
                    id="input-group-2"
                    label="Project Name :"
                    label-for="input-2"
                    class="font-weight-bold"
                  >
                 {{  zones._projectId.name }}
               
              </b-form-group>
          
            <b-form-group
                    id="input-group-2"
                    label=" Zone :"
                    label-for="input-2"
                     class="font-weight-bold"
                  >
                 {{ zones.code }} {{  zones._projectId.name }}
               
              </b-form-group>
            <b-form-group id="input-group-2" label="Code:" label-for="input-2">
              <b-form-input
                id="input-2"
                v-model="plot_form.code"
                placeholder="Enter Plot Code"
                required
              ></b-form-input>
            </b-form-group>

            <b-form-group
              id="input-group-2"
              label="Total area:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                type="number"
                v-model="plot_form.area"
                placeholder="Enter Total area (In Acres)"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="input-group-2"
              label="Survey No:"
              label-for="input-2"
            >
              <b-form-input
                id="input-2"
                type="text"
                v-model="plot_form.survey_no"
                placeholder="Enter Survey No"
                required
              ></b-form-input>
            </b-form-group>

            <div class="row">
              <div class="col-md-6">
                <h5>Boundaries</h5>
              </div>
              <div class="col-md-6 mb-2 text-right">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="addPlotBoundary"
                >
                  New Boundary
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-sm">
                  <thead class="small thead-light">
                    <tr>
                      <th>#</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(boundary, index) in plot_form.boundaries"
                      :key="index"
                    >
                      <td class="small align-middle">
                        {{ index + 1 }}
                      </td>
                      <td>
                        <b-form-input
                          id="input-2"
                          v-model="boundary.latitude"
                          size="sm"
                          placeholder="Enter Latitude"
                          required
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-input
                          id="input-2"
                          v-model="boundary.longitude"
                          size="sm"
                          placeholder="Enter Longitude"
                          required
                        ></b-form-input>
                      </td>
                      <td>
                        <button
                          class="btn btn-sm btn-danger"
                          type="button"
                          @click="removePlotBoundary(index)"
                        >
                          <i class="fa fa-trash"></i>
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, close }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="submitPlotForm()">
          Register
        </b-button>
        <b-button size="sm" variant="danger" @click="close()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      plot_form: {
        _zoneID:"",
        name: "",
        area: "",
        survey_no:"",
        boundaries: [],
      },
       success: false,
    };
  },
  props:['zones'],
  methods: {
    submitPlotForm() {
      this.plot_form._zoneId = this.zones._id;
      this.plot_form._projectId = this.zones._projectId;
      window.axios.post("plot", this.plot_form)
      .then((resp) => {
        
        if(resp.data.success){
          this.success = resp.data.message;
          this.$emit("plotCreateEvent",resp.data);
          this.plot_form._zoneID = "";
          this.plot_form.name = "";
          this.plot_form.area = "";
           this.plot_form.survey_no = "";
          this.plot_form.boundaries = [];
          setTimeout(() => (this.success = false), 3000);
        }
      });
    },
    addPlotBoundary() {
      this.plot_form.boundaries.push({
        latitude: "",
        longitude: "",
      });
    },
    removePlotBoundary(index) {
      this.plot_form.boundaries.splice(index, 1);
    },
  },
};
</script>