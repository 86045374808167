<template>
  <div>
    <b-button v-b-modal="'editPlot' + index" size="sm" variant="success">
      <i class="fa fa-edit"></i>
    </b-button>
    <b-modal :id="'editPlot' + index" title="Edit Plot">
      <b-alert variant="success" v-if="success" show> {{ success }}</b-alert>
      <b-form @submit.prevent="submitPlotForm" enctype="multipart/form-data">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <b-form-group
                  id="input-group-2"
                  label="Project Name"
                  label-for="input-2"
                  class="font-weight-bold"
                >
                  {{ zones._projectId.name }}
                </b-form-group>
              </div>
              <div class="col-md-6">
                <label for="" class="font-weight-bold">
                  Zone
                </label>
                <select
                  name=""
                  id=""
                  class="form-control"
                  v-model="plot_form._zoneId"
                >
                  <option
                    :value="zone._id"
                    v-for="(zone, i) in allZones"
                    :key="i"
                  >
                    {{ zone.code }}</option
                  >
                </select>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-group-2"
                  label="Code:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    v-model="plot_form.code"
                    placeholder="Enter Plot Code"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-group-2"
                  label="Total area:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    type="number"
                    v-model="plot_form.area"
                    placeholder="Enter Total area (In Acres)"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  id="input-group-2"
                  label="Survey No:"
                  label-for="input-2"
                >
                  <b-form-input
                    id="input-2"
                    type="text"
                    v-model="plot_form.survey_no"
                    placeholder="Enter Survey No"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-6">
                <h5>Boundaries</h5>
              </div>
              <div class="col-md-6 mb-2 text-right">
                <button
                  class="btn btn-sm btn-primary"
                  type="button"
                  @click="addPlotBoundary"
                >
                  New Boundary
                </button>
              </div>
              <div class="col-md-12">
                <table class="table table-sm">
                  <thead class="small thead-light">
                    <tr>
                      <th>#</th>
                      <th>Latitude</th>
                      <th>Longitude</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <template v-if="plot_form.boundaries.length > 0">
                      <tr
                        v-for="(boundary, index) in plot_form.boundaries"
                        :key="index"
                      >
                        <td class="small align-middle">
                          {{ index + 1 }}
                        </td>
                        <td>
                          <b-form-input
                            id="input-2"
                            v-model="boundary.latitude"
                            size="sm"
                            placeholder="Enter Latitude"
                            required
                          ></b-form-input>
                        </td>
                        <td>
                          <b-form-input
                            id="input-2"
                            v-model="boundary.longitude"
                            size="sm"
                            placeholder="Enter Longitude"
                            required
                          ></b-form-input>
                        </td>
                        <td>
                          <button
                            class="btn btn-sm btn-danger"
                            type="button"
                            @click="removePlotBoundary(index)"
                          >
                            <i class="fa fa-trash"></i>
                          </button>
                        </td>
                      </tr>
                    </template>
                    <template v-else>
                      <div>
                        <tr class="text-center">
                          <td colspan="4" class="font-weight-bold">
                            No Records
                          </td>
                        </tr>
                      </div>
                    </template>
                  </tbody>
                </table>
              </div>
              <div class="col-md-12">
                <h5>Documentations</h5>
              </div>

              <div class="col-md-12">
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="plot_form.documentations.loi"
                  name="checkbox-1"
                  value="true"
                  unchecked-value="false"
                >
                  Letter of Intent
                </b-form-checkbox>
              </div>

              <div class="col-md-12">
                <b-form-checkbox
                  id="checkbox-2"
                  v-model="plot_form.documentations.sale_agreement"
                  name="checkbox-2"
                  value="true"
                  unchecked-value="false"
                >
                  Sale Agreement
                </b-form-checkbox>
              </div>
              <div class="col-md-12">
                <b-form-checkbox
                  id="checkbox-3"
                  v-model="plot_form.documentations.sale_deed"
                  name="checkbox-3"
                  value="true"
                  unchecked-value="false"
                >
                  Sale Deed
                </b-form-checkbox>
              </div>
              <div class="col-md-12">
                <b-form-checkbox
                  id="checkbox-4"
                  v-model="plot_form.documentations.service_agreement"
                  name="checkbox-4"
                  value="true"
                  unchecked-value="false"
                >
                  Service Agreement
                </b-form-checkbox>
              </div>
              <div class="col-md-12">
                <b-form-checkbox
                  id="checkbox-5"
                  v-model="plot_form.documentations.membership_agreement"
                  name="checkbox-5"
                  value="true"
                  unchecked-value="false"
                >
                  Membership Agreement
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
      </b-form>
      <template #modal-footer="{ ok, close }">
        <!-- Emulate built in modal footer ok and cancel button actions -->
        <b-button size="sm" variant="success" @click="submitEditPlotForm()">
          Update
        </b-button>
        <b-button size="sm" variant="danger" @click="close()"> Close </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios';
export default {
  data() {
    return {
      plot_form: {
        id: '',
        name: '',
        area: '',
        survey_no: '',
        boundaries: [],
        _zoneId: '',
        documentations: {
          loi: false,
          sale_agreement: false,
          sale_deed: false,
          service_agreement: false,
          membership_agreement: false,
        },
      },
      allZones: [],
      success: false,
    };
  },
  props: ['zones', 'index', 'plot'],
  created() {
    this.setPlotInfo();
  },
  mounted() {
    this.getAllZoneForTheProject();
  },
  methods: {
    getAllZoneForTheProject() {
      console.log('getAllZoneForTheProject');
      let project_id = this.zones._projectId._id;
      axios.get('/project-zones/' + project_id).then((resp) => {
        console.log(resp);
        this.allZones = resp.data.data;
      });
    },
    setPlotInfo() {
      this.plot_form = this.plot;
      this.plot_form._zoneId = this.plot._zoneId._id;
      console.log('this.plot_form');
      console.log(this.plot_form._zoneId);
    },
    submitEditPlotForm() {
      this.plot_form._projectId = this.zones._projectId;
      console.log(this.plot_form);
      window.axios
        .put('plots/' + this.plot_form._id, this.plot_form)
        .then((resp) => {
          if (resp.data.success) {
            this.$emit('plotEditEvent', resp.data);
            this.success = resp.data.message;
            setTimeout(() => (this.success = false), 3000);
          }
        });
    },
    addPlotBoundary() {
      this.plot_form.boundaries.push({
        latitude: '',
        longitude: '',
      });
    },
    removePlotBoundary(index) {
      this.plot_form.boundaries.splice(index, 1);
    },
  },
};
</script>
